.footer {
    background-color: #333;
    color: #fff;
    padding: 20px 0;
    text-align: center;
  }
  
  .footer .container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
  }
  
  .footer-section h4 {
    margin-bottom: 10px;
    font-size: 18px;
  }
  
  .footer-slogan {
    font-size: 24px;
    font-weight: bold;
  }
  
  .footer-socials,
  .footer-branches {
    list-style-type: none;
    padding: 0;
  }
  
  .footer-socials li,
  .footer-branches li {
    margin-bottom: 5px;
  }
  
  .footer-socials a {
    color: #fff;
    text-decoration: none;
  }
  
  .footer-socials a:hover {
    text-decoration: underline;
  }
  