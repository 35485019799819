/* {
  background-color: #ffffff;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
} */

html {
  scroll-behavior: smooth;
}

.button-header-nav {
  align-items: center;
  background-color: #ca2525;
  border-radius: 16px;
  display: flex;
  justify-content: center;
  padding: 10px;
  margin:0;
  position: relative;
  color: #fff;
}

.font-bold {
  font-weight: bolder;
}

/* .judul-basic {
  color: #161616;
  font-family: "Poppins", Helvetica;
  font-size: 18px;
  font-weight: 300;
  text-decoration: line-through;
} */

.judul-basic {
  color: #000000;
  font-family: "Poppins", Helvetica;
  font-size: 20px;
  font-weight: 600;
}

.judul-utama {
  color: #000000;
  font-family: "Poppins", Helvetica;
  font-size: 50px;
  font-weight: 600;
}

.judul-subutama {
  color: #000000;
  font-family: "Poppins", Helvetica;
  font-size: 25px;
  font-weight: 600;
}

.judul-basic3 {
  color: #161616;
  font-family: "Poppins", Helvetica;
  font-size: 20px;
  font-weight: 600;
}

.judul-1 {
  color: #ca2525;
  font-family: "Poppins", Helvetica;
  font-size: 30px;
  font-weight: 600;
  padding: 20px 20px 0px 20px;
}
.judul-2 {
  color: #000000;
  font-family: "Poppins", Helvetica;
  font-size: 15px;
  font-weight: 600;
  padding-left: 20px;
}

.desk-1 {
  color: #6c6c6c;
  font-family: "Poppins", Helvetica;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0;
  padding: 20px;
}

.table-new, tr, td {
  color: #000000 !important;
  background: white !important;
  font-size: large;
  font-weight: 700;
  text-align: left;
}



.take-your-placement {
  background-color: #ffffff;
  border-radius: 12px;
  display: flex;
  justify-content: center;
  height: 60px;
  position: relative;
  top: 40px;
}

.take-your-placement .text-wrapper-4 {
  color: #ca2525;
  font-family: "Poppins", Helvetica;
  font-size: 16px;
  font-weight: 600;
  left: 18px;
  letter-spacing: 0.3px;
}

.take-your-placement:hover {
  border: 1px solid;
  border-color: #ffffff;
}

.take-your-placement:hover .text-wrapper-4 {
  color: #ca2525;
  left: 17px;
  top: 17px;
}

.button-header {
  align-items: center;
  background-color: #ca2525;
  border-radius: 16px;
  display: flex;
  height: 62px;
  width: 225px;
  justify-content: center;
  padding: 10px 16px;
  position: relative;
  margin-left: 20px;
}

.button-header .text-wrapper-3 {
  color: #ffffff;
  font-family: "Poppins", Helvetica;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
  width: fit-content;
}

.button-header:hover {
  border: 1px solid;
  border-color: #ca2525;
  box-shadow: 0px 3.85px 3.62px #7f404007, 0px 10.64px 10.02px #7f40400a, 0px 25.63px 24.12px #7f40400d,
    0px 85px 80px #7f404014;
}

.button-header:hover .text-wrapper-3 {
  color: #fff;
}

.div-2 {
  background-color: #ffffff;
  height: 100%;
  position: relative;
  width: 100%;
}

.overlap {
  height: 628px;
  left: 110px;
  position: absolute;
  top: 146px;
  width: 1244px;
}

.text-wrapper-7 {
  color: #ca2525;
  font-family: "Poppins", Helvetica;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
}

.overlap-2 {
  height: 628px;
  left: 0;
  position: absolute;
  top: 0;
  width: 1244px;
}

.kelas-persiapan {
  color: rgb(202, 0, 0);
  font-family: "Poppins", Helvetica;
  font-size: 1.5em;
  font-weight: 550;
  position: relative;
  
}

.mulailah-perjalanan {
  color: #6c6c6c;
  font-family: "Poppins", Helvetica;
  font-size: 18px;
  font-weight: 400;
  position: relative;
}

.overlap-wrapper {
  height: 628px;
  position: relative;
  top: 0;
  width: 691px;
}

.overlap-3 {
  height: 100%;
  width: 100%;
  position: relative;
}

.rectangle-2 {
  background: linear-gradient(
    180deg,
    rgb(248.63, 93.23, 93.23) 0%,
    rgb(195.5, 54.58, 54.58) 63.02%,
    rgb(166.81, 27.8, 27.8) 100%
  );
  border-radius: 204px;
  height: 625px;
  left: 527px;
  position: absolute;
  top: 3px;
  width: 462px;
}

.img {
  height: 625px;
  left: 527px;
  position: absolute;
  top: 3px;
  width: 462px;
}

.group-2 {
  height: 854px;
  left: 0;
  position: absolute;
  top: 86px;
  width: 969px;
}

.component-1 {
  left: 478px !important;
  position: absolute !important;
  top: 0 !important;
}

.component-instance {
  background-color: #ffffff !important;
  border: unset !important;
  border-color: unset !important;
}

.component-1-instance {
  left: 6px !important;
  top: 6px !important;
}

.line {
  height: 1px;
  left: 171px;
  object-fit: cover;
  position: absolute;
  top: 46px;
  width: 119px;
}

.button-header-instance {
  left: 0 !important;
  position: absolute !important;
  top: 446px !important;
}

.overlap-4 {
  height: 254px;
  left: 110px;
  position: absolute;
  top: 903px;
  width: 1144px;
}

.overlap-5 {
  height: 254px;
  left: 0;
  position: absolute;
  top: 0;
  width: 1144px;
}

.line-2 {
  height: 1px;
  left: 187px;
  object-fit: cover;
  position: absolute;
  top: 146px;
  width: 136px;
}

.text-wrapper-8 {
  color: #393939;
  font-family: "Poppins", Helvetica;
  font-size: 48px;
  font-weight: 600;
  left: 0;
  letter-spacing: 1.68px;
  line-height: 76.8px;
  position: absolute;
  top: 46px;
  width: 656px;
}

.text-wrapper-9 {
  color: #ca2525;
  font-family: "Poppins", Helvetica;
  font-size: 18px;
  font-weight: 600;
  left: 0;
  letter-spacing: 0;
  line-height: 31.7px;
  position: absolute;
  top: 128px;
  width: 298px;
}

.group-3 {
  height: 8px;
  left: 589px;
  position: absolute;
  top: 128px;
  width: 68px;
}

.ellipse-2 {
  background-color: #ca2525;
  border-radius: 4px;
  height: 8px;
  left: 0;
  position: absolute;
  top: 0;
  width: 8px;
}

.ellipse-3 {
  background-color: #d9d9d9;
  border-radius: 4px;
  height: 8px;
  left: 20px;
  position: absolute;
  top: 0;
  width: 8px;
}

.ellipse-4 {
  background-color: #d9d9d9;
  border-radius: 4px;
  height: 8px;
  left: 40px;
  position: absolute;
  top: 0;
  width: 8px;
}

.ellipse-5 {
  background-color: #d9d9d9;
  border-radius: 4px;
  height: 8px;
  left: 60px;
  position: absolute;
  top: 0;
  width: 8px;
}

.carousel-frame {
  background-color: #ffffff;
  height: 128px;
  left: 589px;
  overflow: hidden;
  position: absolute;
  top: 0;
}

.carousel {
  align-items: flex-start;
  display: flex;
  gap: 100px;
  position: relative;
  /* width: 555px; */
}

.pathway-university {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  height: 118px;
  justify-content: space-between;
  position: relative;
}

.text-wrapper-10 {
  color: #000000;
  font-family: "Poppins", Helvetica;
  font-size: 36px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  width: 394.67px;
}

.p {
  color: #6c6c6c;
  font-family: "Poppins", Helvetica;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 28px;
  position: relative;
  /* width: 555px; */
}

.pathway-university-2 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  height: 118px;
  justify-content: space-between;
  margin-right: -597.69px;
  position: relative;
}

.text-wrapper-11 {
  color: #000000;
  font-family: "Poppins", Helvetica;
  font-size: 36px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  width: 333.8px;
}

.text-wrapper-12 {
  color: #6c6c6c;
  font-family: "Poppins", Helvetica;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 28px;
  position: relative;
  width: 497.69px;
}

.pathway-university-3 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  height: 172px;
  justify-content: space-between;
  margin-right: -1252.69px;
  position: relative;
}

.bisa-menghemat {
  color: #000000;
  font-family: "Poppins", Helvetica;
  font-size: 36px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  width: 491.09px;
}

.pathway-university-4 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  height: 172px;
  justify-content: space-between;
  margin-right: -1907.69px;
  position: relative;
}

.memberi-peluang {
  color: #000000;
  font-family: "Poppins", Helvetica;
  font-size: 36px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  width: 392.42px;
}

.arrow-1 {
  height: 28px !important;
  left: 517px !important;
  position: absolute !important;
  top: 62px !important;
  width: 28px !important;
}

.text-wrapper-13 {
  color: #ca2525;
  font-family: "Poppins", Helvetica;
  font-size: 16px;
  font-weight: 600;
}

.group-4 {
  height: 168px;
  left: 109px;
  position: absolute;
  top: 4598px;
  width: 547px;
}

.track-any-hashtag-s {
  color: #000000;
  font-family: "Poppins", Helvetica;
  font-size: 36px;
  font-weight: 600;
  left: 0;
  letter-spacing: 0.3px;
  line-height: 52px;
  position: absolute;
  top: 0;
  width: 543px;
}

.don-t-waste-time-on {
  color: #90a3b4;
  font-family: "Poppins", Helvetica;
  font-size: 16px;
  font-weight: 400;
  left: 0;
  letter-spacing: 0.3px;
  line-height: 32px;
  position: absolute;
  top: 136px;
  white-space: nowrap;
}

.group-5 {
  height: 256px;
  left: 754px;
  position: absolute;
  top: 4531px;
  width: 584px;
}

.arrow-down {
  height: 28px;
  left: 554px;
  position: absolute;
  top: 214px;
  width: 28px;
}

.property-1-variant2 {
  height: 28px !important;
  left: 10px !important;
  position: absolute !important;
  top: 214px !important;
  width: 28px !important;
}

.text-wrapper-14 {
  color: #ca2525;
  font-family: "DM Sans", Helvetica;
  font-size: 130px;
  font-weight: 400;
  left: 0;
  letter-spacing: 0.3px;
  line-height: normal;
  position: absolute;
  top: 0;
}

.group-6 {
  height: 204px;
  left: 68px;
  position: absolute;
  top: 52px;
  width: 444px;
}

.group-7 {
  height: 18px;
  left: 2px;
  position: absolute;
  top: 0;
  width: 132px;
}

.text-wrapper-15 {
  color: #ca2525;
  font-family: "Font Awesome 5 Free-Solid", Helvetica;
  font-size: 16px;
  font-weight: 400;
  left: 0;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 0;
  white-space: nowrap;
}

.star {
  color: #ca2525;
  font-family: "Font Awesome 5 Free-Solid", Helvetica;
  font-size: 16px;
  font-weight: 400;
  left: 26px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 0;
  white-space: nowrap;
}

.star-2 {
  color: #ca2525;
  font-family: "Font Awesome 5 Free-Solid", Helvetica;
  font-size: 16px;
  font-weight: 400;
  left: 52px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 0;
  white-space: nowrap;
}

.star-3 {
  color: #ca2525;
  font-family: "Font Awesome 5 Free-Solid", Helvetica;
  font-size: 16px;
  font-weight: 400;
  left: 78px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 0;
  white-space: nowrap;
}

.star-4 {
  color: #ca2525;
  font-family: "Font Awesome 5 Free-Solid", Helvetica;
  font-size: 16px;
  font-weight: 400;
  left: 104px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 0;
  white-space: nowrap;
}

.don-t-waste-time-on-2 {
  color: #90a3b4;
  font-family: "DM Sans", Helvetica;
  font-size: 16px;
  font-style: italic;
  font-weight: 400;
  left: 0;
  letter-spacing: 0;
  line-height: 32px;
  position: absolute;
  top: 32px;
  width: 442px;
}

.group-8 {
  height: 56px;
  left: 0;
  position: absolute;
  top: 148px;
  width: 337px;
}

.jaquon-hart {
  color: #ca2525;
  font-family: "DM Sans", Helvetica;
  font-size: 20px;
  font-weight: 700;
  left: 71px;
  letter-spacing: 0.2px;
  line-height: 24px;
  position: absolute;
  top: 0;
  white-space: nowrap;
}

.digital-marketing-ex {
  color: #90a3b4;
  font-family: "DM Sans", Helvetica;
  font-size: 16px;
  font-weight: 400;
  left: 71px;
  letter-spacing: 0;
  line-height: 28px;
  position: absolute;
  top: 28px;
  white-space: nowrap;
}

.photo {
  height: 56px;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 0;
  width: 56px;
}

.overlap-6 {
  border-radius: 16px;
  height: 274px;
  left: 109px;
  position: absolute;
  top: 4192px;
  width: 1221px;
}

.group-wrapper {
  /* background-color: #ca2525; */
  background-image: url(../../../assets/img/new/mask-group-3.png);
  background-repeat: no-repeat;
  border-radius: 16px;
  height: 248px;
  position: relative;
}

.group-9 {
  height: 139px;
  left: 175px;
  position: relative;
  top: 69px;
  width: 400px;
}

.don-t-waste-time-on-3 {
  color: #ffffff;
  font-family: "Poppins", Helvetica;
  font-size: 14px;
  font-weight: 500;
  left: 0;
}

.track-any-hashtag-s-2 {
  color: #ffffff;
  font-family: "Poppins", Helvetica;
  font-size: 36px;
  font-weight: 600;
}

.take-your-placement-test {
  left: 744px !important;
  position: absolute !important;
  top: 107px !important;
}

.overlap-7 {
  height: 560px;
  left: 110px;
  position: absolute;
  top: 3536px;
  width: 1284px;
}

.group-10 {
  height: 560px;
  left: 6px;
  position: absolute;
  top: 0;
  width: 1115px;
}

.overlap-8 {
  height: 514px;
  left: 0;
  position: absolute;
  top: 0;
  width: 1111px;
}

.overlap-group-wrapper {
  height: 514px;
  left: 551px;
  position: absolute;
  top: 0;
  width: 560px;
}

.overlap-group-2 {
  height: 514px;
  position: relative;
  width: 639px;
}

.ellipse-6 {
  background-color: #f0caca;
  border-radius: 193.14px;
  height: 386px;
  left: 118px;
  position: absolute;
  top: 55px;
  width: 386px;
}

.mask-group-2 {
  height: 497px;
  left: 63px;
  position: absolute;
  top: 0;
  width: 497px;
}

.ellipse-7 {
  border: 5px solid;
  border-color: #ff2e2e80;
  border-radius: 248.5px;
  height: 497px;
  left: 63px;
  position: absolute;
  top: 0;
  width: 497px;
}

.frame-2 {
  align-items: flex-end;
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0px 1.33px 17.5px #fb464615, 0px 2.88px 18.48px #fb46460f, 0px 9px 121px #fb46463b;
  display: flex;
  gap: 10px;
  height: 58px;
  justify-content: center;
  left: 172px;
  padding: 20px 20px 10px;
  position: absolute;
  top: 456px;
  width: 287px;
}

.text-wrapper-16 {
  color: #ca2525;
  font-family: "Poppins", Helvetica;
  font-size: 26px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: normal;
  margin-left: -8px;
  margin-right: -8px;
  margin-top: -12px;
  position: relative;
  text-align: center;
  width: 263px;
}

.IELTS-preparation-wrapper {
  align-items: flex-end;
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0px 1.33px 17.5px #fb464615, 0px 2.88px 18.48px #fb46460f, 0px 9px 121px #fb46463b;
  display: flex;
  gap: 10px;
  height: 76px;
  justify-content: center;
  left: 0;
  padding: 20px 20px 10px;
  position: absolute;
  top: 294px;
  width: 159px;
}

.IELTS-preparation {
  color: #6c6c6c;
  font-family: "Poppins", Helvetica;
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -9px;
  position: relative;
  text-align: center;
  width: fit-content;
}

.heart {
  height: 18px;
  left: 306px;
  position: absolute;
  top: 438px;
  width: 18px;
}

.unsplash-ieojshvwg {
  height: 238px;
  left: 401px;
  position: absolute;
  top: 1px;
  width: 238px;
}

.unsplash-2 {
  background-color: #f6f6f6;
  border: 3px solid;
  border-color: #ffffff;
  border-radius: 30px;
  box-shadow: 0px -0.24px 3.06px #0000000e, 0px -0.61px 7.73px #00000013, 0px -1.24px 15.77px #00000018,
    0px -2.56px 32.49px #0000001e, 0px -7px 89px #0000002b;
  height: 60px;
  left: 139px;
  position: absolute;
  top: 8px;
  width: 60px;
}

.unsplash-3 {
  background-color: #f6f6f6;
  border: 3px solid;
  border-color: #ffffff;
  border-radius: 30px;
  box-shadow: 0px -0.24px 3.06px #0000000e, 0px -0.61px 7.73px #00000013, 0px -1.24px 15.77px #00000018,
    0px -2.56px 32.49px #0000001e, 0px -7px 89px #0000002b;
  height: 60px;
  left: 490px;
  position: absolute;
  top: 351px;
  width: 60px;
}

.document {
  height: 26px;
  left: 156px;
  position: absolute;
  top: 25px;
  width: 26px;
}

.iconly-bold-shield {
  height: 21px;
  left: 511px;
  position: absolute;
  top: 370px;
  width: 18px;
}

.text-wrapper-17 {
  color: #000000;
  font-family: "Poppins", Helvetica;
  font-size: 36px;
  font-weight: 600;
  left: 0;
  letter-spacing: 1.26px;
  line-height: 57.6px;
  position: absolute;
  top: 140px;
  width: 586px;
}

.text-wrapper-18 {
  color: #6c6c6c;
  font-family: "Poppins", Helvetica;
  font-size: 18px;
  font-weight: 400;
  left: 0;
  letter-spacing: 0;
  line-height: 28.8px;
  position: absolute;
  top: 211px;
  width: 387px;
}

.group-11 {
  height: 8px;
  left: 832px;
  position: absolute;
  top: 552px;
  width: 68px;
}

.arrow-2 {
  height: 166px !important;
  left: 457px !important;
  position: absolute !important;
  top: 160px !important;
  width: 166px !important;
}

.arrow-3 {
  height: 166px !important;
  left: 1118px !important;
  position: absolute !important;
  top: 170px !important;
  width: 166px !important;
}

.book-your-lessons-instance {
  left: 0 !important;
  position: absolute !important;
  top: 333px !important;
}

.overlap-9 {
  background-image: url(../../../assets/img/new/rectangle-91.svg);
  background-size: 100% 100%;
  height: 998px;
  left: 10px;
  position: absolute;
  top: 4823px;
  width: 1420px;
}

.overlap-10 {
  border-radius: 0px 16px 16px 0px;
  height: 798px;
  left: 100px;
  position: absolute;
  top: 96px;
  width: 584px;
}

.rectangle-3 {
  background: linear-gradient(180deg, rgb(199.75, 35.79, 35.79) 0%, rgb(241, 98, 98) 100%);
  border-radius: 0px 16px 16px 0px;
  height: 798px;
  left: 0;
  position: absolute;
  top: 0;
  width: 584px;
}

.group-12 {
  height: 652px;
  left: 65px;
  position: absolute;
  top: 73px;
  width: 451px;
}

.group-13 {
  height: 76px;
  left: 0;
  position: absolute;
  top: 0;
  width: 455px;
}

.text-wrapper-19 {
  color: #ffffff;
  font-family: "Poppins", Helvetica;
  font-size: 16px;
  font-weight: 400;
  left: 0;
  letter-spacing: 0.3px;
  line-height: normal;
  position: absolute;
  top: 0;
}

.overlap-group-3 {
  background-color: #ffffff;
  border-radius: 10px;
  height: 41px;
  left: 1px;
  position: absolute;
  top: 35px;
  width: 450px;
}

.text-wrapper-20 {
  color: #90a3b4;
  font-family: "Poppins", Helvetica;
  font-size: 14px;
  font-style: italic;
  font-weight: 300;
  left: 30px;
  letter-spacing: 0.3px;
  line-height: normal;
  position: absolute;
  top: 12px;
}

.group-14 {
  height: 6px;
  left: 419px;
  position: absolute;
  top: 20px;
  width: 10px;
}

.group-15 {
  height: 76px;
  left: 0;
  position: absolute;
  top: 96px;
  width: 455px;
}

.group-16 {
  height: 76px;
  left: 0;
  position: absolute;
  top: 192px;
  width: 455px;
}

.group-17 {
  height: 76px;
  left: 0;
  position: absolute;
  top: 288px;
  width: 455px;
}

.group-18 {
  height: 76px;
  left: 0;
  position: absolute;
  top: 384px;
  width: 455px;
}

.group-19 {
  height: 76px;
  left: 0;
  position: absolute;
  top: 480px;
  width: 455px;
}

.group-20 {
  height: 76px;
  left: 0;
  position: absolute;
  top: 576px;
  width: 455px;
}

.group-21 {
  height: 54px;
  left: 782px;
  position: absolute;
  top: 169px;
  width: 285px;
}

.text-wrapper-21 {
  color: #000000;
  font-family: "Poppins", Helvetica;
  font-size: 36px;
  font-weight: 600;
  left: 0;
  letter-spacing: 0.3px;
  line-height: normal;
  position: absolute;
  top: 0;
}

.group-22 {
  height: 27px;
  left: 782px;
  position: absolute;
  top: 265px;
  width: 466px;
}

.group-23 {
  height: 24px;
  left: 0;
  position: absolute;
  top: 0;
  width: 53px;
}

.text-wrapper-22 {
  color: #000000;
  font-family: "Poppins", Helvetica;
  font-size: 16px;
  font-weight: 400;
  left: 0;
  letter-spacing: 0.3px;
  line-height: normal;
  position: absolute;
  top: 0;
}

.group-24 {
  height: 24px;
  left: 151px;
  position: absolute;
  top: 0;
  width: 6px;
}

.line-3 {
  height: 1px;
  left: 169px;
  object-fit: cover;
  position: absolute;
  top: 26px;
  width: 297px;
}

.group-25 {
  height: 27px;
  left: 782px;
  position: absolute;
  top: 308px;
  width: 466px;
}

.group-26 {
  height: 24px;
  left: 0;
  position: absolute;
  top: 0;
  width: 117px;
}

.group-27 {
  height: 27px;
  left: 782px;
  position: absolute;
  top: 347px;
  width: 466px;
}

.group-28 {
  height: 24px;
  left: 0;
  position: absolute;
  top: 0;
  width: 47px;
}

.group-29 {
  height: 68px;
  left: 782px;
  position: absolute;
  top: 388px;
  width: 466px;
}

.group-30 {
  height: 24px;
  left: 0;
  position: absolute;
  top: 0;
  width: 62px;
}

.line-4 {
  height: 1px;
  left: 169px;
  object-fit: cover;
  position: absolute;
  top: 27px;
  width: 297px;
}

.line-5 {
  height: 1px;
  left: 169px;
  object-fit: cover;
  position: absolute;
  top: 68px;
  width: 297px;
}

.text-wrapper-23 {
  color: #000000;
  font-family: "Poppins", Helvetica;
  font-size: 18px;
  font-weight: 400;
  left: 1140px;
  letter-spacing: 0.3px;
  line-height: normal;
  position: absolute;
  text-align: right;
  top: 683px;
}

.text-wrapper-24 {
  color: #000000;
  font-family: "Poppins", Helvetica;
  font-size: 28px;
  font-weight: 700;
  left: 1073px;
  letter-spacing: 0.3px;
  line-height: normal;
  position: absolute;
  text-align: right;
  top: 711px;
}

.text-wrapper-25 {
  color: #90a3b4;
  font-family: "Poppins", Helvetica;
  font-size: 14px;
  font-style: italic;
  font-weight: 400;
  left: 782px;
  letter-spacing: 0.3px;
  line-height: 32px;
  position: absolute;
  top: 513px;
  width: 466px;
}

.line-6 {
  height: 1px;
  left: 782px;
  object-fit: cover;
  position: absolute;
  top: 644px;
  width: 466px;
}

.daftar-register-instance {
  left: 1059px !important;
  position: absolute !important;
  top: 785px !important;
}

.overlap-11 {
  height: 2248px;
  left: 110px;
  position: absolute;
  top: 1158px;
  width: 1223px;
}

.program {
  height: 2248px;
  left: 0;
  position: absolute;
  top: 0;
  width: 1223px;
}

.text-wrapper-26 {
  color: #000000;
  font-family: "Poppins", Helvetica;
  font-size: 36px;
  font-weight: 600;
  left: 343px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 0;
}

.we-provide-various {
  color: #6c6c6c;
  font-family: "Poppins", Helvetica;
  font-size: 18px;
  font-weight: 400;
  left: 303px;
  letter-spacing: 0;
  line-height: 28.8px;
  position: absolute;
  text-align: center;
  top: 75px;
  width: 614px;
}

.general-english {
  height: 368px;
  left: 0;
  position: absolute;
  top: 1044px;
  width: 1219px;
}

.overlap-12 {
  height: 368px;
  position: relative;
  width: 1223px;
}

.group-31 {
  height: 368px;
  left: 0;
  position: absolute;
  top: 0;
  width: 1223px;
}

.overlap-group-4 {
  background-color: #ffffff;
  border-radius: 16px;
  box-shadow: 0px -2px 128px #8e050524;
  position: relative;
}

.text-wrapper-27 {
  color: #ca2525;
  font-family: "Poppins", Helvetica;
  font-size: 24px;
  font-weight: 600;
  left: 39px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 70px;
}

.text-wrapper-28 {
  color: #6c6c6c;
  font-family: "Poppins", Helvetica;
  font-size: 14px;
  font-weight: 400;
  left: 39px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 172px;
  width: 189px;
}

.text-wrapper-new {
  color: #ca2525;
  font-family: "Poppins", Helvetica;
  font-size: 24px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: normal;
}

.group-32 {
  height: 273px;
  left: 303px;
  position: absolute;
  top: 40px;
  width: 210px;
}

.overlap-13 {
  background-image: url(../../../assets/img/new/rectangle-13-16.svg);
  background-size: 100% 100%;
  height: 373px;
  left: -50px;
  position: relative;
  top: -40px;
  width: 300px;
}

.overlap-group-5 {
  background-color: #ffffff;
  border-radius: 16px;
  height: 204px;
  left: 50px;
  position: absolute;
  top: 109px;
  width: 200px;
}

.text-wrapper-29 {
  color: #000000;
  font-family: "Poppins", Helvetica;
  font-size: 20px;
  font-weight: 600;
  left: 26px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 25px;
  width: 146px;
}

.min-students-max {
  color: #6c6c6c;
  font-family: "Poppins", Helvetica;
  font-size: 12px;
  font-weight: 400;
  left: 48px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 76px;
  width: 124px;
}

.text-wrapper-30 {
  color: #6c6c6c;
  font-family: "Poppins", Helvetica;
  font-size: 12px;
  font-weight: 400;
  left: 48px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 132px;
  width: 124px;
}

.group-33 {
  height: 9px;
  left: 25px;
  position: absolute;
  top: 91px;
  width: 13px;
}

.group-34 {
  height: 9px;
  left: 25px;
  position: absolute;
  top: 136px;
  width: 13px;
}

.overlap-group-6 {
  height: 40px;
  left: 76px;
  position: absolute;
  top: 55px;
  width: 146px;
}

.text-wrapper-31 {
  color: #ffffff;
  font-family: "Poppins", Helvetica;
  font-size: 18px;
  font-weight: 600;
  position: relative;
  text-align: center;;
}

.text-wrapper-32 {
  color: #ffffff;
  font-family: "Poppins", Helvetica;
  font-size: 14px;
  font-weight: 400;
  left: 0;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 22px;
  width: 146px;
}

.group-35 {
  height: 273px;
  left: 743px;
  position: absolute;
  top: 40px;
  width: 210px;
}

.overlap-14 {
  background-image: url(../../../assets/img/new/rectangle-13-15.svg);
  background-size: 100% 100%;
  height: 373px;
  left: -50px;
  position: relative;
  top: -40px;
  width: 300px;
}

.group-36 {
  height: 273px;
  left: 523px;
  position: absolute;
  top: 40px;
  width: 210px;
}

.overlap-15 {
  background-image: url(../../../assets/img/new/rectangle-13-14.svg);
  background-size: 100% 100%;
  height: 373px;
  left: -50px;
  position: relative;
  top: -40px;
  width: 300px;
}

.group-37 {
  height: 273px;
  left: 963px;
  position: absolute;
  top: 40px;
  width: 210px;
}

.overlap-16 {
  background-image: url(../../../assets/img/new/rectangle-13-13.svg);
  background-size: 100% 100%;
  height: 373px;
  left: -50px;
  position: relative;
  top: -40px;
  width: 300px;
}

.general-english-2 {
  height: 368px;
  left: 0;
  position: absolute;
  top: 1462px;
  width: 1219px;
}

.overlap-17 {
  background-image: url(../../../assets/img/new/rectangle-13-12.svg);
  background-size: 100% 100%;
  height: 373px;
  left: -50px;
  position: relative;
  top: -40px;
  width: 300px;
}

.overlap-18 {
  background-image: url(../../../assets/img/new/rectangle-13-11.svg);
  background-size: 100% 100%;
  height: 373px;
  left: -50px;
  position: relative;
  top: -40px;
  width: 300px;
}

.overlap-19 {
  background-image: url(../../../assets/img/new/rectangle-13-10.svg);
  background-size: 100% 100%;
  height: 373px;
  left: -50px;
  position: relative;
  top: -40px;
  width: 300px;
}

.overlap-20 {
  background-image: url(../../../assets/img/new/rectangle-13-9.svg);
  background-size: 100% 100%;
  height: 373px;
  left: -50px;
  position: relative;
  top: -40px;
  width: 300px;
}

.general-english-3 {
  height: 368px;
  left: 0;
  position: absolute;
  top: 1880px;
  width: 1219px;
}

.overlap-21 {
  background-image: url(../../../assets/img/new/rectangle-13-8.svg);
  background-size: 100% 100%;
  height: 373px;
  left: -50px;
  position: relative;
  top: -40px;
  width: 300px;
}

.overlap-22 {
  background-image: url(../../../assets/img/new/rectangle-13-7.svg);
  background-size: 100% 100%;
  height: 373px;
  left: -50px;
  position: relative;
  top: -40px;
  width: 300px;
}

.overlap-23 {
  background-image: url(../../../assets/img/new/rectangle-13-6.svg);
  background-size: 100% 100%;
  height: 373px;
  left: -50px;
  position: relative;
  top: -40px;
  width: 300px;
}

.overlap-24 {
  background-image: url(../../../assets/img/new/rectangle-13-5.svg);
  background-size: 100% 100%;
  height: 373px;
  left: -50px;
  position: relative;
  top: -40px;
  width: 300px;
}

.general-english-4 {
  height: 368px;
  left: 0;
  position: absolute;
  top: 626px;
  width: 1219px;
}

.overlap-25 {
  background-image: url(../../../assets/img/new/frame02.png);
  background-size: cover;
  background-repeat: no-repeat;
}

.overlap-26 {
  background-image: url(../../../assets/img/new/frame03.png);
  background-size: cover;
  background-repeat: no-repeat;
}

.overlap-27 {
  background-image: url(../../../assets/img/new/frame04.png);
  background-size: cover;
  background-repeat: no-repeat;
}

.overlap-28 {
  background-image: url(../../../assets/img/new/frame05.png);
  background-size: cover;
  background-repeat: no-repeat;
}

.general-english-5 {
  height: 368px;
  left: 0;
  position: absolute;
  top: 208px;
  width: 1219px;
}

.overlap-29 {
  height: 368px;
  position: relative;
  width: 1225px;
}

.group-38 {
  height: 368px;
  left: 0;
  position: absolute;
  top: 0;
  width: 1225px;
}

.text-wrapper-33 {
  color: #ca2525;
  font-family: "Poppins", Helvetica;
  font-size: 32px;
  font-weight: 600;
  position: relative;
}

.overlap-30 {
  background-image: url(../../../assets/img/new/frame01.png);
  background-size: cover;
  background-repeat: no-repeat;
  margin: 20px;
}

.design-component-instance-node {
  height: 56px !important;
  left: 560px !important;
  position: absolute !important;
  top: 442px !important;
  width: 231px !important;
}

.frame-3 {
  align-items: center;
  background-color: #ffb700;
  border-radius: 35px;
  box-shadow: 0px 4px 10px #0000001a;
  display: inline-flex;
  gap: 10px;
  justify-content: center;
  left: 843px;
  padding: 5px 10px;
  position: absolute;
  top: 653px;
}

.text-wrapper-34 {
  color: #000000;
  font-family: "Poppins", Helvetica;
  font-size: 11px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  text-align: center;
  width: fit-content;
}

.arrow-instance {
  height: 28px !important;
  left: 1287px !important;
  position: absolute !important;
  top: 965px !important;
  width: 28px !important;
}

.footer {
  background-color: transparent;
  height: 458px;
  left: 0;
  position: absolute;
  top: 5891px;
  width: 1440px;
}

.overlap-31 {
  height: 458px;
  position: relative;
  width: 1440px;
}

.group-39 {
  background-color: #000000;
  height: 458px;
  left: 0;
  position: absolute;
  top: 0;
  width: 1440px;
}

.overlap-32 {
  height: 141px;
  left: 690px;
  position: relative;
  top: 153px;
  width: 531px;
}

.group-40 {
  height: 141px;
  left: 0;
  position: absolute;
  top: 0;
  width: 267px;
}

.build-a-modern-and-c {
  color: #ffffff;
  font-family: "DM Sans", Helvetica;
  font-size: 16px;
  font-weight: 400;
  left: 0;
  letter-spacing: 0;
  line-height: 28px;
  position: absolute;
  top: 107px;
  width: 263px;
}

.text-wrapper-35 {
  color: #ffffff;
  font-family: "Poppins", Helvetica;
  font-size: 16px;
  font-weight: 600;
  left: 0;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 0;
  white-space: nowrap;
  width: 118px;
}

.icon {
  height: 26px;
  left: 0;
  overflow: hidden;
  position: absolute;
  top: 54px;
  width: 42px;
}

.overlap-group-7 {
  border-radius: 2000px;
  height: 26px;
  position: relative;
}

.img-2 {
  height: 15px;
  left: 14px;
  object-fit: cover;
  position: absolute;
  top: 5px;
  width: 15px;
}

.layer {
  background-color: #ffffff1a;
  border-radius: 2000px;
  height: 26px;
  left: 0;
  position: absolute;
  top: 0;
  width: 42px;
}

.layer-wrapper {
  height: 26px;
  left: 48px;
  overflow: hidden;
  position: absolute;
  top: 54px;
  width: 42px;
}

.img-wrapper {
  background-color: #ffffff1a;
  border-radius: 2000px;
  height: 26px;
  position: relative;
}

.icon-2 {
  height: 26px;
  left: 97px;
  overflow: hidden;
  position: absolute;
  top: 54px;
  width: 40px;
}

.youtube-white {
  height: 17px;
  left: 12px;
  object-fit: cover;
  position: absolute;
  top: 4px;
  width: 17px;
}

.icon-3 {
  height: 26px;
  left: 145px;
  overflow: hidden;
  position: absolute;
  top: 54px;
  width: 40px;
}

.facebook-white {
  height: 17px;
  left: 11px;
  object-fit: cover;
  position: absolute;
  top: 3px;
  width: 17px;
}

.group-41 {
  height: 124px;
  left: 263px;
  position: absolute;
  top: 0;
  width: 267px;
}

.build-a-modern-and-c-2 {
  color: #ffffff;
  font-family: "DM Sans", Helvetica;
  font-size: 16px;
  font-weight: 400;
  left: 0;
  letter-spacing: 0;
  line-height: 28px;
  position: absolute;
  top: 54px;
  width: 263px;
}

.group-42 {
  height: 151px;
  left: 110px;
  position: absolute;
  top: 150px;
  width: 411px;
}

.overlap-33 {
  height: 151px;
  position: relative;
  width: 413px;
}

.group-43 {
  height: 151px;
  left: 0;
  position: absolute;
  top: 0;
  width: 413px;
}

.overlap-group-8 {
  height: 151px;
  position: relative;
  width: 409px;
}

.don-t-waste-time-on-4 {
  color: #ffffff;
  font-family: "Poppins", Helvetica;
  font-size: 14px;
  font-weight: 500;
  left: 0;
  letter-spacing: 0.3px;
  line-height: 32px;
  position: absolute;
  top: 118px;
  white-space: nowrap;
  width: 409px;
}

.item {
  color: #ffffff;
  font-family: "Roboto", Helvetica;
  font-size: 32px;
  font-weight: 400;
  left: 225px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 52px;
  white-space: nowrap;
  width: 36px;
}

.line-7 {
  height: 353px;
  left: 581px;
  object-fit: cover;
  position: absolute;
  top: 54px;
  width: 1px;
}

.header-menu {
  height: 92px;
  left: 0;
  position: fixed;
  top: 0;
  width: 1442px;
}

.overlap-34 {
  background-color: #ffffff;
  height: 92px;
  position: relative;
  width: 1440px;
}

.line-header {
  height: 1px;
  left: 107px;
  object-fit: cover;
  position: absolute;
  top: 91px;
  width: 1223px;
}

.menu-header {
  left: 700px !important;
  position: absolute !important;
  top: 25px !important;
}

.text-wrapper-36 {
  color: #393939;
  font-family: "Poppins", Helvetica;
  font-size: 18px;
  font-weight: 700;
  left: 110px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 32px;
  width: 355px;
}

.group-44 {
  height: 120px;
  left: 1295px;
  position: fixed;
  top: 667px;
  width: 95px;
}

.text-wrapper-37 {
  color: #6c6c6c;
  font-family: "Poppins", Helvetica;
  font-size: 16px;
  font-weight: 600;
  left: 26px;
  letter-spacing: 0;
  line-height: 25.6px;
  position: absolute;
  text-align: center;
  top: 94px;
  width: 67px;
}

.chat-us {
  left: 0 !important;
  position: absolute !important;
  top: 0 !important;
}

@media (min-width: 200px) and (max-width: 768px) {
  .overlap-30 {
    margin: 40px
  }
  
  .overlap-25 {
    margin: 40px
  }

  .overlap-26 {
    margin: 40px
  }

  .overlap-27 {
    margin: 40px
  }

  .overlap-28 {
    margin: 40px
  }
}

@media (max-width> 844px) {
  
}

@media (max-width> 767px) {

}

@media (width< 767px) {
  .judul-utama {
    color: #000000;
    font-family: "Poppins", Helvetica;
    font-size: 30px;
    font-weight: 600;
  }
  .judul-subutama {
    color: #000000;
    font-family: "Poppins", Helvetica;
    font-size: 18px;
    font-weight: 600;
    padding: 10px 0;
    margin: 0;
  }
  .kelas-persiapan {
    color: rgb(202, 0, 0);
    font-family: "Poppins", Helvetica;
    font-size: 2em;
    font-weight: 550;
    position: relative;
  }
  .overlap-30 {
    padding: 0;
    margin: 0 25px 50px 25px;
  }
  .free {
    font-size: 13px;
  }


}


.carousel-container {
  background-color: white; /* Latar belakang putih */
  border-radius: 10px;     /* Sudut melengkung */
  padding: 2rem;          /* Ruang dalam */
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1); /* Efek bayangan */
}

.carousel-container p {
  margin-bottom: 1rem;
  color: #555; /* Warna teks deskripsi */
}

#trial, #placement{
  scroll-margin-top: 100px; 
}