
 @import '~rc-easyui/dist/themes/metro/easyui.css';
 @import '~rc-easyui/dist/themes/icon.css';
 @import '~rc-easyui/dist/themes/react.css';

:root {
    background-color:white;
}

.limonasi-logo {
    //src: './../img/limonasi-logo/Logo-1_10-10.png';
    width: 70px;
    height: 70px;
}

.limonasi-logo2 {
    //src: './../img/limonasi-logo/Logo-1_10-10.png';
    width: 200px;
    height: 70px;
}

// .limonasi-logo-color {
//     //src: './../img/limonasi-logo/Logo-1-05.png';
//     width: 130pt;
//     height: 30pt;
// }

.bg-header-banner {
    background-color: white;
}

.img-fluid-logo-banner {
    height: auto;
    width: 100%;
}

.membership-logo-motto{
    max-width: 400pt;
    max-height: 400pt;
    align-items: center;
}

.membership-card{
    width: 380pt;
    height: 250pt;
    //border: 1px solid #ccc; 
    padding: 0px;
}

.section1-infobox{
    color: white;
    font-weight: bold;
}

.flex-center {
    display: flex;
    align-items: center;
    justify-content: center;
}

.youtube-responsive-container {
    position:relative;
    padding-bottom:56.25%;
    padding-top:30px;
    height:0;
    overflow:hidden;
    }
    
.youtube-responsive-container iframe, .youtube-responsive-container object, .youtube-responsive-container embed {
    position:absolute;
    top:0;
    left:0;
    width:100%;
    height:100%;
    }

.youtube-center{
    display: flex;
    justify-content: center;
    flex-direction: column;
}


// .caution-responsive-container {
//     position:relative;
//     padding-bottom:56.25%;
//     padding-top:30px;
//     height:0;
//     overflow:hidden;
//     }
    
.titleContainer{
    position:relative;
    padding-bottom:56.25%;
    padding-top:30px;
    height:0;
    overflow:hidden;
    }

.content-center{
    display: flex;
    justify-content: center;
    flex-direction: column;
}



.headerBold{
    font-weight: 500;
    color: white;
}

.title-custom{
    font-weight: 500;
    margin-bottom: 0;
    color: white; 
}

.title-custom2{
    color: white; 
}

// .imgPlanctonicWrap{
//     display: inline-block;
//     float:right;

// }

.imgPlanctonic{
    width: 200px;
    display: flex;
    justify-content: center;
    flex-direction: row;  
}

.descriptionBold{
    font-size: 15pt;
    font-weight: 200;
    color: white;
}

.title1{
    color: #C39B3F;
    margin-bottom: 20px;
}

.fontcolorgold{
    color: #C39B3F;
}

.bgcolorgold{
    background-color: #C39B3F;
}

.title2{
    color: white;
    margin-bottom: 20px;
}



.imgshape{
    width: 30%;
    margin-left: auto;
    margin-right: auto;  
    display: block;
}



table { 
    width: 100%; 
    border-collapse: collapse; 
  }
  /* Zebra striping */
  tr:nth-of-type(odd) { 
    background: #eee; 
  }
  th { 
    background: #333; 
    color: #5F727F; 
    font-weight: bold; 
    width: 10%;
    font-size: 15pt;
  }
  td {
    font-size: 12pt;
  }
  td, th { 
    padding: 6px; 
    //border: 1px solid #ccc; 
    text-align: center; 
  }
  .cellBrown {
      background-color: #C6AD8F;
  }
  .cellLightBrown {
      background-color: #F6F4F2;
  }
  .cellWhite {
      background-color: #FFFFFF;
  }

.sec5_content1 {
    background-color: #C6AD8F;
}
  
.sec5_content2 {
    background-color: #F6F4F2;
}

@media only screen and (max-width: 1000px) {
    .setDisplayNone {
        display: none;
    }

}

@media only screen and (max-width: 400px) {
    .membership-logo-motto{
        max-width: 200pt;
        max-height: 200pt;
    }

    .membership-card{
        width: 250pt;
        height: 180pt;
        //border: 1px solid #ccc; 
        padding: 0px;
    }
}

.carousel-item {
    height: 30vh;
    min-height: 100px;
    background: no-repeat center center scroll;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }

.page-header-register{
    background-color: $header-background;
    background-position: center center;
    background-size: cover;
    min-height: 100vh;
    max-height: 999px;
    overflow: hidden;
    position: static;
    width: 100%;
    z-index: 1;

    &.page-header-register-small{
        min-height: 65vh !important;
        max-height: 700px;
    }
    &.page-header-register-xs{
        min-height: 40vh !important;
    }

    .page-header-register-image{
        position: absolute;
        background-size: cover;
        background-position: center center;
        width: 100%;
        height: 100%;
        z-index: -1;
    }


    .content-center{
       position: absolute;
       top: 50%;
       left: 50%;
       z-index: 3;
       -ms-transform: translate(-50%,-50%);
       -webkit-transform: translate(-50%,-50%);
       transform: translate(-50%,-50%);
       text-align: center;
       color: #FFFFFF;
       width: 100%;
   }

    .motto{
        color: #FFFFFF;
        text-align: center;
        z-index: 2;
        position: relative;
    }

    .filter{
      &::after{
        background-color: rgba(0, 0, 0, 0.5);
        content: "";
        display: block;
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
        z-index: 2;
      }
      &.filter-primary::after{
          background-color: rgba($filter-primary, 0.5);
      }
      &.filter-info::after{
          background-color: rgba($filter-info, 0.5);
      }
      &.filter-success::after{
          background-color: rgba($filter-success, 0.5);
      }
      &.filter-warning::after{
          background-color: rgba($filter-warning, 0.5);
      }
      &.filter-danger::after{
          background-color: rgba($filter-danger, 0.5);
      }
    }


    .container{
        color: $white-color;
        position: relative;
        z-index: 3;
    }
    .description{
        color: $white-color;
    }
}


.form-signup{
    color: #425664;
}

.navbg {
    background-color: red;
}

.bennefit-logo{
    margin: -60px auto 0;
}

.list-contact{
    width: 500px;
    height: 500px;

}


/*======================================
       16. FOOTER1
========================================*/
.benefit-icon{
    width: 120px;
    height: 110px;
}
.social-icon {
    // border: 3px solid #7A7A7A;
    border-radius: 50%;
    color: white !important;
    width: 40px;
    height: 40px;
    display: block;
    font-size: 25px;
    line-height: 30px;
    text-align: center;
}

.cta {
    padding: 20px 0;
}

.social-icon:hover i {
    transition: all 0.5s;
}

.footer1 {
    padding: 50px 0;
    background-color: #000;
    font-weight: 500;
    box-shadow: 6px 6px 10px 4px rgba(0, 0, 0, 0.15);
}
.footer1 .social{
    margin-top: 15px;
    font-weight: 500;
}
.footer1-bottom{
    margin-top: 30px;
    padding: 15px;
    font-size: 14px;
}
.footer1 h4 {
    color: white;
    font-size: 15px;
}
.contact-us{
    padding-left: 25px;
}
.contact-us ul {
    padding: 0;
    list-style: none;
}
.contact-us ul li {
    font-size: 13px;
}
.contact-us ul li label{
    margin: 0;
}
.footer1-list li a {
    color: #98a0aa;
    padding-top: 8px;
    display: inline-block;
    font-size: 14px;
    transition: all .3s ease-in-out;
}

.footer1-list li a:hover {
    color: #dce6f3;
}

.contact-title {
    font-size: 20px;
}

.contact-detail {
    padding-left: 25px;
    font-size: 12px;
}

.subscribe {
    position: relative;
    width: 100%;
    margin-top: 15px;
    margin-bottom: 25px;
}

.subscribe input {
    height: 40px;
    background-color: #2e313a !important;
    box-shadow: none !important;
    border: none;
    padding-right: 50px;
    color: #fff !important;
}

.subscribe .submit {
    position: absolute;
    top: 0;
    right: 0;
    font-size: 20px;
    width: 50px;
    line-height: 45px;
    text-align: center;
    color: #acacac !important;
}

.footer1-alt-border {
    background-color: #f5f5f5;
    height: 1px;
    width: 100%;
}

.footer1-alt {
    padding: 15px 0;
    background-color: #2e313a;
}

.copy-rights {
    margin-bottom: 0;
    line-height: 36px;
}

// .consultant1 {
//     background-image: url('./../img/foto-consultant/Square_Foto_Staff-04.png');
//     background-size: cover;
//     width: 160px;
//     height: 160px;
//     padding-bottom: 10px;
//     //src: './../img/limonasi-logo/Logo-1_10-10.png';
// }


.divkon1 {
    //display: inline-block;
    overflow: hidden;
    position: relative;
    width: 100%;
    height: 100%;
}

.imgkon1 {
    //pointer-events: none;
    position: absolute;
    width: 90%;
    height: 83%;
    z-index: -1;
}

.bg-content-3-container{
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.vcard{
    margin-top: 150px;
    width: 400px;
    height: 100%;
    
}

.event-1{
    width: 400px;
    height: 100px;
}

.ambassadorBold{
    font-weight: 500;
}

.none {
    display: none;
}

.bg-overlay2 {
    /*background: rgba(11,11,11,0.3);
    /* background: url("../images/header/Bg-texture.png"); */
    // background: url('./../img/bg2-01.png');
    // background: url('./../img/bg-covid-03.png');
    /* background-color: #DFEBEC; */
    /* background-color: #47AEE0; */
    background-repeat: no-repeat;
    background-size: cover;

    opacity: 0.60;
    position: absolute;
    height: 100%;
    width: 100%;
    right: 0;
    bottom: 0;
    left: 0;
    top: 0;
    background: black; /* Old browsers */
background: -moz-linear-gradient(-45deg,  black 0%, rgba(219,127,127,1) 99%); /* FF3.6-15 */
background: -webkit-linear-gradient(-45deg,  black 0%,rgba(219,127,127,1) 99%); /* Chrome10-25,Safari5.1-6 */
background: linear-gradient(135deg,  black 0%,rgba(219,127,127,1) 99%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#e02527', endColorstr='#db7f7f',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */

}

@media only screen and (max-width: 3000px) {
    .responsiveImg {
        background-repeat: no-repeat;
        background-size: cover;
    }
    .fontButton {
        // background-color:#45a30e;
        font-size: 15pt;
    }
}

@media only screen and (max-width: 1024px) {
    .responsiveImg {
        background-repeat: no-repeat;
        background-size: cover;
        width: 100%;
        height: auto;
        background-size: 100% 100%;
    }
    .fontButton {
        background-color:#45a30e;
        font-size: 9pt;
    }
}
@media (max-width: 768px) {
    .responsiveImg {
        background-repeat: no-repeat;
        background-size: cover;
        width: 100%;
        height: auto;
        background-size: 100% 100%;
    }
}
@media only screen and (max-width: 464px) {
    .responsiveImg {
        background-repeat: no-repeat;
        background-size: cover;
        width: 100%;
        height: auto;
        background-size: 100% 100%;
    }
    .fontButton {
        background-color:#45a30e;
        font-size: 9pt;
    }
}


.navbarGradient {
    /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#e02527+0,db7f7f+99 */
background: rgb(224,37,39); /* Old browsers */
background: -moz-linear-gradient(-45deg,  rgba(224,37,39,1) 0%, rgba(219,127,127,1) 99%); /* FF3.6-15 */
background: -webkit-linear-gradient(-45deg,  rgba(224,37,39,1) 0%,rgba(219,127,127,1) 99%); /* Chrome10-25,Safari5.1-6 */
background: #ca2525; /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#e02527', endColorstr='#db7f7f',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */

}

.noborder {
    border: none;
}